// routes
import { PATH_DASHBOARD } from '../../../routes/paths'
// components
import SvgIconStyle from '../../../components/SvgIconStyle'
import Iconify from '../../../components/Iconify'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

const ICONS = {
  dashboard: <Iconify icon={'clarity:document-solid'} width={10} height={10} />,
  user: getIcon('ic_user'),
}
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      // Les icônes sont stockés dans front/src/icons et sont des .svg
      // TODO: Supprimer les svg et utiliser la bibliothèque MUI pour les icônes
      {
        title: 'menu.quotes',
        path: PATH_DASHBOARD.general.quotes,
        icon: ICONS.dashboard,
      }
    ],
  },
]

export default navConfig
