// @mui
import { styled } from '@mui/material/styles'
// components
import Page from '../components/Page'
import MainHeader from '../layouts/main/MainHeader'
import MainFooter from '../layouts/main/MainFooter'
import { SubscriptionHero, SubscriptionForm } from '../sections/subscription'
import useLocales from '../hooks/useLocales'
import { Container, Grid, Typography } from '@mui/material'
import { useState } from 'react'

const RootStyle = styled('div')(({ theme }) => {
  return {
    height: '100%',
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(11),
    },
  }
})
const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  plan: '',
  phone: '',
}

export default function Subscribe() {
  const { translate: t } = useLocales()
  const [infoState, setInfoState] = useState(defaultValues)
  const [errors, setErrors] = useState<any[]>([])

  return (
    <Page title={t('subscription.title')}>
      <RootStyle>
        <MainHeader />
        <SubscriptionHero />
        <Container sx={{ mt: 15, mb: 10 }}>
          <Grid row-rowGap={6} item xs={12} md={12} lg={12}>
            <Typography variant="h3" paragraph>
              {t('subscription.choose_a_plan')}
            </Typography>
            <br></br>
            <Typography variant="body2" paragraph>
              {t('subscription.advantage_of_annual')}
            </Typography>

            <br></br>
            <SubscriptionForm
              errors={errors}
              setErrors={setErrors}
              infoState={infoState}
              updateInfoState={setInfoState}
            />
          </Grid>
        </Container>
        <MainFooter />
      </RootStyle>
    </Page>
  )
}
