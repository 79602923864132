import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material'
// routes
import { PATH_PAGE } from '../../routes/paths'
// components
import Logo from '../../components/Logo'
import SocialsButton from '../../components/SocialsButton'
import useLocales from '../../hooks/useLocales'

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'MOBILIO',
    children: [
      { name: 'footer.who-we-are', href: PATH_PAGE.aboutUs },
      {
        name: 'footer.contact-us',
        href: PATH_PAGE.contactUs,
      },
      { name: 'footer.documentation', href: PATH_PAGE.documentation },
      { name: 'footer.faq_words', href: PATH_PAGE.faq },
    ],
  },
  {
    headline: 'footer.about',
    children: [
      { name: 'privacy.title', href: PATH_PAGE.privacy },
      { name: 'cgvu.title', href: PATH_PAGE.cgvu },
      { name: 'legal_mentions.title', href: PATH_PAGE.legalMentions },
      { name: 'about_us.title', href: PATH_PAGE.aboutUs },
    ],
  },
]

const RootStyle = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  }
})

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { translate: t } = useLocales()
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
          <Grid item xs={8} md={4}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {t('footer.description')}
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {t(list.headline)}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {t(link.name)}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {t('footer.copyright')}
        </Typography>
      </Container>
    </RootStyle>
  )
}
