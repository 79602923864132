// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import RtlLayout from './components/RtlLayout'
import { ChartStyle } from './components/chart'
import ScrollToTop from './components/ScrollToTop'
import { ProgressBarStyle } from './components/ProgressBar'
import NotistackProvider from './components/NotistackProvider'
import ThemeColorPresets from './components/ThemeColorPresets'
import ThemeLocalization from './components/ThemeLocalization'
import MotionLazyContainer from './components/animate/MotionLazyContainer'
import { scroller } from 'react-scroll'
import { LiveChatLoaderProvider, HubSpot } from 'react-live-chat-loader'
import { CookieBanner } from './components/CookieBanner'

// ----------------------------------------------------------------------

export default function App() {
  window.onload = function () {
    scroller.scrollTo(window.location.hash.substring(1), {
      duration: 500,
      delay: 10,
      smooth: true,
    })
  }

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <ScrollToTop />
                  <Router />
                  <CookieBanner>
                    <LiveChatLoaderProvider providerKey="144596294" provider="hubSpot">
                      <HubSpot />
                    </LiveChatLoaderProvider>
                  </CookieBanner>
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  )
}
