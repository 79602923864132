// @mui
import { useTheme } from '@mui/material/styles'
import { SvgIcon } from '@mui/material'

// ----------------------------------------------------------------------

export default function LogoMin() {
  const theme = useTheme()
  const PRIMARY_MAIN = theme.palette.primary.main

  return (
    <SvgIcon viewBox="0 0 192 192" sx={{ width: '100%', height: '100%' }}>
      <rect x="5.625" y="9.75" width="180.75" height="172.5" rx="20" fill="white" />
      <path
        d="M61.38 56.7866L61.3756 56.7891C58.1776 58.5737 55.5068 61.0664 53.3535 64.218L53.3525 64.2194C51.2315 67.3264 49.6558 70.9322 48.5927 74.9935C47.5132 79.0685 47 83.4939 47 88.2428V135V139H51H59.2894H63.2894V135V90.9188C63.2894 84.3632 64.5415 79.6873 66.6235 76.4629C67.9137 74.4831 69.4076 73.3187 71.0992 72.7195L71.1091 72.716L71.119 72.7124C71.771 72.4776 73.1861 72.2474 74.9532 72.206C76.7021 72.1651 78.1827 72.3227 78.905 72.5229L78.9161 72.5259L78.9271 72.5289C80.9183 73.0684 82.6093 74.2839 84.0201 76.4612L84.0217 76.4637C86.1103 79.6815 87.3553 84.3527 87.3553 90.9188V135V139H91.3553H99.6447H103.645V135V90.9188C103.645 84.3589 104.898 79.6852 106.989 76.4637L106.993 76.4583C108.265 74.4912 109.767 73.3216 111.466 72.7192L111.476 72.7158L111.485 72.7124C112.138 72.4774 113.551 72.2474 115.315 72.206C117.061 72.1651 118.544 72.3223 119.276 72.5241L119.283 72.526L119.29 72.528C121.271 73.0667 122.964 74.2825 124.375 76.4612L124.377 76.4637C126.468 79.6852 127.722 84.3589 127.722 90.9188V135V139H131.722H140H144V135V88.2428C144 83.4909 143.486 79.0686 142.422 74.9997L142.421 74.9982C141.357 70.9366 139.783 67.3283 137.658 64.218C135.505 61.0671 132.836 58.5737 129.635 56.7886L129.629 56.7855C125.973 54.7538 120.501 53.9939 115.644 54C110.788 54.0062 105.343 54.7797 101.735 56.7866L101.731 56.7891C99.3694 58.1069 97.2953 59.8109 95.5048 61.8811C93.7109 59.8095 91.6346 58.1077 89.2856 56.7921L89.2738 56.7855C85.6173 54.7538 80.1458 53.9939 75.2883 54C70.4329 54.0062 64.9873 54.7797 61.38 56.7866Z"
        fill={PRIMARY_MAIN}
        stroke={PRIMARY_MAIN}
        strokeWidth="8"
      />
    </SvgIcon>
  )
}
