// @mui
import { styled } from '@mui/material/styles'
import { Container, Typography, Button, Stack } from '@mui/material'
// components
import Page from '../components/Page'
import MainHeader from '../layouts/main/MainHeader'
import MainFooter from '../layouts/main/MainFooter'
import { varFade, MotionInView } from '../components/animate'
// paths
import { PATH_CONTACT } from '../routes/paths'
// hooks
import useLocales from '../hooks/useLocales'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => {return {
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11),
  },
}})

// ----------------------------------------------------------------------

export default function Documentation() {
  const { translate: t } = useLocales()
  return (<Page title="Faqs">
  <RootStyle>
    <MainHeader />
    <Container sx={{ mt: 15, mb: 10 }}>
      <Typography variant="h3" sx={{ mb: 5 }}>
        {t('header.documentation')}
      </Typography>
      <iframe src="/pdfs/2024-06-documentation-mobilio.pdf" width="100%" height={700}></iframe>
      <Stack spacing={3}>
        <Stack spacing={3}>
        <MotionInView sx={{ mt: 15, mb: 5 }} variants={varFade().inUp}>
          <Typography variant="h4">{t('contact_form.faq_title')}</Typography>
        </MotionInView>
        <MotionInView variants={varFade().inUp}>
          <Button size="large" variant="contained" href={PATH_CONTACT}>
            {t('home.price.contact-us')}
          </Button>
        </MotionInView>
        </Stack>
      </Stack>
    </Container>
    <MainFooter />
  </RootStyle>
</Page>)
}
