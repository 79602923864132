import { useEffect, useState } from 'react'
import axiosInstance from '../utils/axios'
import { isEmpty, isNull, isNumber, isObject, isUndefined } from 'lodash'

const sample = {
  "email": "",
  "phone": "",
  "company": "",
  "legalName": "",
  "socialCapital": 0,
  "siret": "",
  "vatNumber": "",
  "registration": "",
  "address": "",
  "address_meta": {
    "place_id": "",
    "address": {
      "postal_code": "",
      "city": "",
      "country": "",
      "state": "",
      "street_name": "",
      "_id": ""
    },
    "location": {
      "lat": 0,
      "lng": 0,
      "_id": ""
    },
    "_id": ""
  },
  "insuranceName": "",
  "furnitureAmount": 0,
  "maxPricePerFurniture": 0,
  "franchise": 0,
  "depositPercent": 0,
  "cgv": "",
  "logo": ""
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isComplete = (sample: Object, object: Object): boolean => {
  const state = Object.keys(sample).map((key) => {
    if (isObject(sample[key])) {
      return isComplete(sample[key], object[key] || {}) 
    }
    return !(isNull(object[key]) || (!isNumber(object[key]) && isEmpty(object[key])) || isUndefined(object[key]))
  })

  return state.reduce((acc, cur) => acc && cur, true)
}

const useProfileIsComplete = () => {
  const [company, setCompany] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    async function fetchCompany() {
      const response = await axiosInstance.get(`/api/configuration`)
      setCompany(response.data.result)
      setLoading(false)
    }
    if (Object.keys(company).length === 0 ) {
      setLoading(false)
    }
    fetchCompany()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return [isComplete(sample, company), loading, company]
}

export default useProfileIsComplete
