import { Box, Button, Link, Typography } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
// import useLocales from './hooks/useLocales'
import { Link as RouterLink } from 'react-router-dom'
import { PATH_PAGE } from '../routes/paths'
import useLocalStorage from '../hooks/useLocalStorage'
//
// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => {
  return {
    right: 0,
    bottom: '0px',
    position: 'fixed',
    marginTop: theme.spacing(-3),
    padding: '2em 4em',
    width: '100%',
    backgroundColor: `${
      theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.common.black
    }`,
    zIndex: theme.zIndex.drawer + 10,
    color: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
    boxShadow: `0px -10px 11px 0px  ${alpha(
      theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
      0.36
    )}`,
  }
})

export const CookieBanner = ({ children }: any) => {
  const [cookieAccepted, setAcceptedCookie] = useLocalStorage('cookieAccepted', null)
  const [bannerDisplayed, setBannerDisplayed] = useLocalStorage('bannerDisplayed', false)

  const onAccept = ()=> {
    setAcceptedCookie(true)
    setBannerDisplayed(true)
  }

  const onReject = ()=> {
    setAcceptedCookie(false)
    setBannerDisplayed(true)
  }
  // const { translate: t } = useLocales()

  if (cookieAccepted) {
    return (<>{children}</>)
  }
  if (!bannerDisplayed) {
    return (
      <RootStyle>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}
        >
          <div>
            <Typography variant="h3" component="div">
              Le respect de vos données est notre priorité !
            </Typography>
            <Typography variant="body1" component="div">
              Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. En
              continuant à naviguer sur notre site, vous acceptez l’utilisation des cookies.{' '}
              <Link
                to={PATH_PAGE.privacy}
                key="privacy"
                color="inherit"
                variant="body2"
                component={RouterLink}
              >
                En savoir plus
              </Link>
            </Typography>
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              marginTop: '10px',
            }}
          >
            <Button
              sx={{ marginBottom: '10px' }}
              onClick={onAccept}
              variant="contained"
              color="warning"
            >
              J'accepte
            </Button>
            <Button
              onClick={onReject}
              variant="text"
              sx={{ color: 'black', textDecoration: 'underline' }}
            >
              Refuser
            </Button>
          </Box>
        </Box>
      </RootStyle>
    )
  }
  
  return (<></>)
}
