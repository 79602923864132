import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// routes
import { PATH_AUTH } from '../../../routes/paths'
// hooks
import useAuth from '../../../hooks/useAuth'
import useIsMountedRef from '../../../hooks/useIsMountedRef'
import useLocales from '../../../hooks/useLocales'
import { useSnackbar } from 'notistack'
// components
import Iconify from '../../../components/Iconify'
import { FormProvider, RHFTextField } from '../../../components/hook-form'
import RegisterLink from '../register/RegisterLink'
//
import * as Yup from 'yup'

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string
  password: string
  remember: boolean
  afterSubmit?: string
}

export default function LoginForm() {
  const { translate: t } = useLocales()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { login } = useAuth()
  const isMountedRef = useIsMountedRef()

  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('auth.email_error')).required(t('auth.email_required')),
    password: Yup.string().required(t('auth.password_required')),
  })

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
  })

  const {
    // reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    const email = data.email
    try {
      await login(email, data.password)
    } catch (error: any) {
      if (error == "User is deleted") {
        enqueueSnackbar(<RegisterLink email={data.email} closeSnackbar={closeSnackbar()} />, { variant: 'error' })
      } else if (isMountedRef.current) {
        setError('afterSubmit', error)
      }
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{t`auth.error`}</Alert>}

        <RHFTextField name="email" label={t`auth.email`} data-testid="loginForm-email-input" />

        <RHFTextField
          name="password"
          label={t`auth.password`}
          type={showPassword ? 'text' : 'password'}
          data-testid="loginForm-password-input"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack
        direction="row-reverse"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Link
          component={RouterLink}
          variant="subtitle2"
          to={PATH_AUTH.resetPassword}
          onClick={() => handleSubmit(onSubmit)}
          data-testid="loginForm-forgetPassword-button"
        >
          {t`auth.forgot_password`}
        </Link>
        {/*<RHFCheckbox name="remember" label={checkBoxLabel} />*/}
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        data-testid="loginForm-loadingButton-button"
        loading={isSubmitting}
      >
        {t`auth.login`}
      </LoadingButton>
    </FormProvider>
  )
}
