import { styled } from '@mui/material/styles'
import { Box, Container, Stack } from '@mui/material'
import { MotionContainer, TextAnimate } from '../../components/animate'
import useLocales from '../../hooks/useLocales'

const RootStyle = styled('div')(({ theme }) => {
  return {
    backgroundSize: 'cover',
    backgroundImage: ' url(/images/overlay.svg),url(/images/SubscriptionHero.jpg);',
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('md')]: {
      height: 560,
      padding: 0,
    },
  }
})

const ContentStyle = styled(Stack)(({ theme }) => {
  return {
    textAlign: 'center',
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      position: 'absolute',
      bottom: theme.spacing(10),
    },
  }
})

export default function SubscriptionHero() {
  const { translate: t } = useLocales()
  const title = t('subscription.title')
  const titleParts = title.split(' ')

  return (
    <RootStyle>
      <Container component={MotionContainer} sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle spacing={5}>
          <Box
            sx={{ display: 'inline-flex', color: 'common.white', width: '100%', flexWrap: 'wrap' }}
          >
            {titleParts.map((part, key) => (
              <TextAnimate text={part} key={key} sx={{ mr: 2 }} />
            ))}
          </Box>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
