// components
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
}

const menuConfig = [
  {
    title: 'header.application',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/#how-work',
  },
  {
    title: 'header.documentation',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: '/documentation',
  },
  {
    title: 'header.faq',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: '/faq',
  },
]

export default menuConfig
