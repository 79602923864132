import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
// @mui
import { Box, List } from '@mui/material'
// routes
import { PATH_AUTH } from '../../../routes/paths'
// hooks
import useAuth from '../../../hooks/useAuth'
import useLocales from '../../../hooks/useLocales'
//components
import Iconify from '../../../components/Iconify'

import {
  ListItemTextStyle,
  ListItemIconStyle,
  ListItemStyle,
} from '../../../components/nav-section/vertical/style'
// ----------------------------------------------------------------------

export default function LogoutActionButton() {
  const navigate = useNavigate()
  const { translate: t } = useLocales()

  const { logout } = useAuth()

  const { enqueueSnackbar } = useSnackbar()

  const handleLogout = async () => {
    try {
      await logout()
      navigate(PATH_AUTH.login, { replace: true })
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Unable to logout!', { variant: 'error' })
    }
  }

  return (
    <Box data-testid="logout-button">
      <List key="logout" disablePadding sx={{ px: 2 }}>
        <ListItemStyle sx={{ color: 'text.secondary' }} onClick={handleLogout}>
          <ListItemIconStyle>
            <Iconify icon={'akar-icons:cross'} />
          </ListItemIconStyle>
          <ListItemTextStyle disableTypography primary={t(`logout`)} />
        </ListItemStyle>
      </List>
    </Box>
  )
}
