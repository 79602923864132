import { Link as RouterLink } from 'react-router-dom'
// @mui
import { Stack, Button, Typography, Link } from '@mui/material'
// hooks
import useAuth from '../../../hooks/useAuth'
// routes
import { PATH_CONTACT, PATH_DOCUMENTATION, PATH_FAQ } from '../../../routes/paths'
// assets
import useLocales from '../../../hooks/useLocales'

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useAuth()
  const { translate: t } = useLocales()

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        textAlign: 'center',
        display: 'block',
      }}
    >
      <div>
        <Typography gutterBottom variant="subtitle1">
          {t('navbar.hi', { name: user?.firstName })}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {t('navbar.need_help')}
          <br />
          <Link
            to={PATH_FAQ}
            key="faq"
            color="inherit"
            variant="body2"
            underline="always"
            component={RouterLink}
          >
            {t('navbar.read_faq')}
          </Link>
          <br />
          <Link
            to={PATH_CONTACT}
            key="contact"
            color="inherit"
            variant="body2"
            underline="always"
            component={RouterLink}
          >
            {t('navbar.contact_us')}
          </Link>
        </Typography>
      </div>
      <Button
        href={PATH_DOCUMENTATION}
        target="_blank"
        rel="noopener"
        variant="outlined"
        color="primary"
        data-testid="navbarVertical-Doc-button"
      >
        {t('navbar.docs')}
      </Button>
    </Stack>
  )
}
