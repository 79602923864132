import axios from 'axios'
// config
import { HOST_API } from '../config'

// ----------------------------------------------------------------------
const axiosInstance = axios.create({
  baseURL: HOST_API,
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Axios error:', error)
    const errorMessage = error.response?.data?.message || 'Something went wrong'
    return Promise.reject(errorMessage)
  }
)

export default axiosInstance
