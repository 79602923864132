import isString from 'lodash/isString'
import { useDropzone } from 'react-dropzone'
// @mui
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// type
import { UploadProps } from './type'
//
import Image from '../Image'
import Iconify from '../Iconify'
import RejectionFiles from './RejectionFiles'
import useLocales from '../../hooks/useLocales'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => {
  return {
    width: '100%',
    height: 200,
    margin: 'auto',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
  }
})

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
})

const PlaceholderStyle = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': { opacity: 0.72 },
  }
})

// ----------------------------------------------------------------------

export default function UploadAvatar({ error, file, sx, ...other }: UploadProps) {
  const { translate: t } = useLocales()
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  })

  return (
    <>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />

          {file && (
            <Image alt="avatar" src={isString(file) ? file : file.preview} sx={{ zIndex: 8 }} />
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 },
              }),
              ...((isDragReject || error) && {
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">{file ? `${t('configuration.field.logo_modify')}` : `${t(`configuration.field.logo_upload`)}`}</Typography>
            <Typography variant="caption">{t('configuration.field.logo_helper')}</Typography>
            <Typography variant="caption">{t('configuration.field.logo_size_warning')}</Typography>
          </PlaceholderStyle>

        </DropZoneStyle>

      </RootStyle>
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  )
}
