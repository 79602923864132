// @mui
import { styled } from '@mui/material/styles'
import { Box, Container, Typography } from '@mui/material'
// components
import Page from '../components/Page'
// assets
import MobilioTruck from '../assets/images/mobilio_truck.svg'
import Image from '../components/Image'
import useLocales from '../hooks/useLocales'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => {
  return {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
  }
})

// ----------------------------------------------------------------------

export default function ComingSoon() {
  const { translate: t } = useLocales()

  return (
    <Page title="Coming Soon" sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              {t('coming_soon.title')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{t('coming_soon.description')}</Typography>
            <Image src={MobilioTruck} sx={{ my: 10, height: 240 }} />
          </Box>
        </Container>
      </RootStyle>
    </Page>
  )
}
