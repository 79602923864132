import { Link as RouterLink } from 'react-router-dom'
// @mui
import { Box, BoxProps } from '@mui/material'
import useCollapseDrawer from '../hooks/useCollapseDrawer'
import LogoLarge from '../assets/logo_large'
import LogoMin from '../assets/logo_min'

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const { isCollapse } = useCollapseDrawer()

  const logo = (
    <Box sx={{ width: 100, height: 40, ...sx }}>{!isCollapse ? <LogoLarge /> : <LogoMin />}</Box>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return <RouterLink to="/">{logo}</RouterLink>
}
