import isString from 'lodash/isString'
import { useDropzone } from 'react-dropzone'
// @mui
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// type
import { UploadProps } from './type'
//
import Iconify from '../Iconify'
import RejectionFiles from './RejectionFiles'
import useLocales from '../../hooks/useLocales'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => {
  return {
    width: '100%',
    height: 100,
    margin: 'auto',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
  }
})

const PDFStyle = styled('embed')(({ theme }) => {
  return {
    width: '100%',
    height: 500,
    margin: 'auto',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    position: 'relative',
  }
})

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  '& > *': { width: '100%', height: '100%' },
  cursor: 'pointer',
  '& .placeholder': {
    zIndex: 9,
  },
})

const PlaceholderStyle = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    opacity: 0.72,
  }
})

// ----------------------------------------------------------------------

export default function UploadPDF({ error, file, sx, ...other }: UploadProps) {
  const { translate: t } = useLocales()
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  })

  return (
    <>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />
          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...((isDragReject || error) && {
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <Iconify icon={'mdi:file-pdf-box'} sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">
              {file
                ? `${t('configuration.field.cgv_modify')}`
                : `${t(`configuration.field.cgv_upload`)}`}
            </Typography>
            <Typography variant="caption">{t('configuration.field.cgv_helper')}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>
      {file && <PDFStyle src={isString(file) ? file : file.preview} />}
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  )
}
