// @mui
import {
  TextField,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Stack,
  Grid,
} from '@mui/material'
import useLocales from '../../hooks/useLocales'
import axiosInstance from '../../utils/axios'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'

type ConfigurationProps = {
  infoState: any
  updateInfoState: any
  errors: any
  setErrors: any
}

export default function SubscriptionForm(props: ConfigurationProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { translate: t } = useLocales()
  const { state }: any = useLocation()

  let default_value = ''
  if (state == null) {
    default_value = 'MONTHLY'
  } else {
    default_value = state.default_value
  }
  const { updateInfoState, infoState, errors, setErrors } = props
  const {
    infoState: { first_name, last_name, email, phone, plan },
  } = props

  let selected_plan = default_value
  if (plan) selected_plan = plan

  const handleChange = (setState) => (event) =>
    setState((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value }
    })

  const yupValidator: any = {
    first_name: Yup.string().required(`${t(`subscription.field.first_name_required`)} `),
    last_name: Yup.string().required(`${t(`subscription.field.last_name_required`)}`),
    email: Yup.string()
      .email(`${t(`subscription.field.email_`)}`)
      .required(`${t(`subscription.field.email_required`)}`),
    phone: Yup.string().required(`${t(`subscription.field.phone_required`)} `),
  }

  const SendData = async (
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    plan: string
  ) => {
    const errorsTmp: any[] = []
    await Yup.object()
      .shape(yupValidator)
      .validate(infoState, { abortEarly: false })
      .then(async () => {
        setErrors([])
        const body = {
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          plan: plan,
        }
        const result = await axiosInstance.post('api/subscription/subscribe', body)
        result.status === 200
          ? enqueueSnackbar(t('subscription.notification.success'))
          : enqueueSnackbar(t('subscription.notification.failed'), { variant: 'error' })
      })
      .catch((err) => {
        console.log(err)
        setErrors(errorsTmp)
      })
  }

  const onChange = handleChange(updateInfoState)

  return (
    <Grid item xs={12} md={8}>
      <Box
        sx={{
          display: 'grid',
          columnGap: 2,
          rowGap: 2,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
          },
        }}
      >
        <TextField
          placeholder={t('subscription.field.first_name')}
          label={t('subscription.field.first_name')}
          name="first_name"
          value={first_name}
          onChange={onChange}
          error={!!errors.first_name}
          helperText={errors.first_name}
          variant="outlined"
          fullWidth
        />
        <TextField
          placeholder={t('subscription.field.last_name')}
          label={t('subscription.field.last_name')}
          name="last_name"
          value={last_name}
          onChange={onChange}
          error={!!errors.last_name}
          helperText={errors.last_name}
          variant="outlined"
          fullWidth
        />
        <TextField
          placeholder={email}
          label={t('subscription.field.email')}
          name="email"
          value={email}
          onChange={onChange}
          error={!!errors.email}
          helperText={errors.email}
          variant="outlined"
          fullWidth
        />
        <TextField
          placeholder={phone}
          label={t('subscription.field.phone')}
          name="phone"
          value={phone}
          onChange={onChange}
          error={!!errors.phone}
          helperText={errors.phone}
          variant="outlined"
          fullWidth
        />
        <FormControl>
          <InputLabel id="plan">{t('subscription.field.plan')}</InputLabel>
          <Select
            placeholder={t('subscription.field.plan')}
            value={selected_plan}
            onChange={onChange}
            name="plan"
            labelId="plan"
            label={t('subscription.field.plan')}
          >
            <MenuItem value="MONTHLY" key="0">
              {t('subscription.field.monthly_plan')}
            </MenuItem>
            <MenuItem value="ANNUAL" key="1">
              {t('subscription.field.annual_plan')}
            </MenuItem>
          </Select>
        </FormControl>
        <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" sx={{ mt: 3 }}>
          <Button
            size="large"
            href=""
            rel="noopener"
            variant="contained"
            onClick={() => SendData(first_name, last_name, email, phone, selected_plan)}
          >
            {t('subscription.title')}
          </Button>
        </Stack>
      </Box>
    </Grid>
  )
}
