import { Suspense, lazy, ElementType } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// guards
import useAuth from '../hooks/useAuth'
// layouts
import DashboardLayout from '../layouts/dashboard'
// guards
import GuestGuard from '../guards/GuestGuard'
import AuthGuard from '../guards/AuthGuard'
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen'
import Tutoriel from '../pages/Tutoriel'
import Subscribe from '../pages/Subscription'
import Documentation from '../pages/Documentation'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth()

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    // Landing page
    {
      path: '',
      element: <Home />,
    },
    // reload page
    { path: '/reload', element: null },
    // Authentication
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <RouterSwitch />, index: true },
        {
          path: 'path-optimization',
          children: [
            {
              path: 'schema',
              element: <PathOptimizationSchema />,
            },
            {
              path: 'quote',
              children: [
                { path: ':id', element: <PathOptimizationListForQuote /> },
                { path: ':id/schema', element: <PathOptimizationSchema /> },
              ],
            },
          ],
        },
        { path: 'configuration', element: <ConfigurationForm /> },
        {
          path: 'quotes',
          children: [
            { element: <QuoteList />, index: true },
            { path: 'add/:type', element: <QuoteAddUpdate /> },
            { path: 'edit/:quoteId', element: <QuoteAddUpdate edit /> },
            {
              path: 'view',
              children: [{ path: ':id', element: <QuoteView /> }],
            },
            {
              path: 'editView',
              children: [{ path: ':id', element: <QuoteEdit /> }],
            },
          ],
        },
        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: 'account', element: <UserAccount /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      children: [
        { path: 'legal-mentions', element: <Legal legalPage="legal_mentions" /> },
        { path: 'privacy', element: <Legal legalPage="privacy" /> },
        { path: 'cgvu', element: <Legal legalPage="cgvu" /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'tutoriel', element: <Tutoriel /> },
        { path: 'about-us', element: <Legal legalPage="about_us" /> },
        { path: 'contact-us', element: <ContactUs /> },
        { path: 'subscribe', element: <Subscribe /> },
        { path: 'documentation', element: <Documentation /> },
        { path: 'faq', element: <FAQ /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      children: [{ path: '/', element: <Navigate to="/auth/login" replace /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}

// IMPORT COMPONENTS

// Home page
const Home = Loadable(lazy(() => import('../pages/Home')))
// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')))
const Register = Loadable(lazy(() => import('../pages/auth/Register')))
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')))
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')))
// Dashboard
const QuoteList = Loadable(lazy(() => import('../pages/dashboard/quotes/QuoteList')))
const QuoteAddUpdate = Loadable(lazy(() => import('../pages/dashboard/quotes/QuoteAddUpdate')))
const QuoteView = Loadable(lazy(() => import('../pages/dashboard/quotes/View')))
const QuoteEdit = Loadable(lazy(() => import('../pages/dashboard/quotes/Edit')))
const PathOptimizationListForQuote = Loadable(
  lazy(() => import('../pages/dashboard/pathOptimization/listForQuote'))
)

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')))
const ConfigurationForm = Loadable(lazy(() => import('../pages/dashboard/ConfigurationForm')))
// Main
const Legal = Loadable(lazy(() => import('../pages/Legal')))
// const AboutUs = Loadable(lazy(() => import('../pages/AboutUs')))
const ContactUs = Loadable(lazy(() => import('../pages/ContactUs')))
const FAQ = Loadable(lazy(() => import('../pages/FAQ')))
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')))
const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const NotFound = Loadable(lazy(() => import('../pages/Page404')))

// switches
const RouterSwitch = Loadable(lazy(() => import('./switch/IndexSwitch')))
const PathOptimizationSchema = Loadable(
  lazy(() => import('../pages/dashboard/pathOptimization/schemaQuote'))
)
