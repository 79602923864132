import numeral from 'numeral'

// ----------------------------------------------------------------------

export function fCurrency(number: number) {
  return numeral(number).format('0.00')
}

export function fEuros(currency: string) {
  return currency.replaceAll(',', ' ').replaceAll('.', ',') + ' €'
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0%')
}

export function fNumber(number: string | number) {
  return numeral(number).format()
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '')
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b')
}
