import { Link as RouterLink } from "react-router-dom"
import { Link } from '@mui/material'
import { PATH_AUTH } from "../../../routes/paths"
import useLocales from "../../../hooks/useLocales"

// Register link for deleted user
export default function RegisterLink(user, closeSnackbar) {
 const { translate: t } = useLocales()
  return (
    <>
    {t('auth.user_deleted', { email: user.email })}&nbsp;
    <span>
      <Link
        component={RouterLink}
        to={PATH_AUTH.register}
        onClick={() => closeSnackbar(true).reset()} 
      >
        {t`auth.user_deleted_register`}
      </Link>
    </span>
    &nbsp;{t('auth.user_deleted_end')}
  </>
  )
}